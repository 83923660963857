<template>
  <section id="pricing" class="section has-background-white">
    <div class="container">
      <div class="columns">
        <div class="column has-text-centered">
          <h1 class="title">Un prix adapté à vos besoins</h1>
          <p class="mb-12">Vous payez sur base mensuelle selon votre volume de commandes.<br/>Notre formule de prix se veut flexible, transparente et adaptée à votre activité.</p>
        </div>
      </div>

      <div class="columns plans">
        <div class="column is-half-tablet is-one-third-widescreen">
          <div class="card plan plan--small">
            <div class="card-content">
              <div class="plan__head">
                <h2 class="plan__name">Starter</h2>
                <div class="plan__price">
                  <div class="price__value price__value--noprice">Contactez-nous</div>
                </div>
              </div>
              <hr>
              <div class="plan__features">
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  <strong>Testez</strong> notre application
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Jusqu'à <strong>500 transactions</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Fonctionnalités limitées
                </div>
                <hr>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                 Design à <strong>vos couleurs</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Mises à jour <strong>gratuites</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Fonctionnement <strong>24x7</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="column is-half-tablet is-one-quarter-widescreen">
          <div class="card plan plan--large">
            <div class="card-content">
              <div class="plan__head">
                <h2 class="plan__name">Medium</h2>
                <div class="plan__price">
                  <div class="price__value">250</div>
                  <div class="price__symbol">€ / mois</div>
                </div>
              </div>
              <hr>
              <div class="plan__features">
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  <strong>1250 transactions</strong> par mois
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Pas d'abonnement
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  <strong>0.20€</strong> par transaction supplémentaire
                </div>
                <hr>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Design à <strong>vos couleurs</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Consultation de <strong>documents</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Intégration ERP
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Mises à jour <strong>gratuites</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Maintenance technique <strong>24x7</strong>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="column is-half-tablet is-one-third-widescreen">
          <div class="card plan plan--medium">
            <div class="card-content">
              <div class="plan__head">
                <h2 class="plan__name">Pro</h2>
                <div class="plan__price">
                  <div class="price__value">500</div>
                  <div class="price__symbol">€ / mois</div>
                </div>
              </div>
              <hr>
              <div class="plan__features">
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Inclu <strong>3.000 transactions</strong> par mois
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Abonnement annuel
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Les <strong>1.000 transactions</strong> supplémentaires pour 100€ par mois
                </div>
                <hr>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Design à <strong>vos couleurs</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Consultation de <strong>documents</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Intégration <strong>ERP</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Mises à jour <strong>gratuites</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Fonctionnement <strong>24x7</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Support par <strong>Email</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-half-tablet is-one-third-widescreen">
          <div class="card plan plan--extralarge">
            <div class="card-content">
              <div class="plan__head">
                <h2 class="plan__name">Business</h2>
                <div class="plan__price">
                  <div class="price__value price__value--noprice">Contactez-nous</div>
                </div>
              </div>
              <hr>
              <div class="plan__features">
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  <strong>Plus de 3.000 transactions</strong> par&nbsp;mois&nbsp;?
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Besoins <strong>spécifiques</strong> ?
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Un prix à la <strong>transaction</strong> ?
                </div>
                <hr>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Design à <strong>vos couleurs</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Consultation de <strong>documents</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Intégration <strong>ERP</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Mises à jour <strong>gratuites</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Fonctionnement <strong>24x7</strong>
                </div>
                <div class="features__item">
                  <svg class="checkmark" width="16" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 11.0002L16.5001 0.500122L17.5 1.49997L6.70715 12.2928C6.31663 12.6833 5.68346 12.6833 5.29294 12.2928L0.500122 7.49997L1.49997 6.50012L6.00005 11.0002Z" fill="#313131"></path></svg>
                  Support <strong>téléphonique</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column has-text-centered">
          <p class="mt-4">Tous nos prix s'entendent Hors TVA</p>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'Pricing',
};
</script>

<style lang="scss" scoped>
@import '@/shared/sass/_variables';
.plans {
  flex-wrap: wrap;
}
.plan {
  height: 100%;
  border: 1px solid #eeeeee;
  border-top: 6px solid black;
}
.plan--small {
  border-top-color: $cyan;
  & .plan__name {
    color: $cyan;
  }
}
.plan--medium {
  border-top-color: $primary;
  & .plan__name {
    color: $primary;
  }
}
.plan--large {
  border-top-color: $orange-light;
  & .plan__name {
    color: $orange-light;
  }
}
.plan--extralarge {
  border-top-color: $orange;
  & .plan__name {
    color: $orange;
  }
}

.plan__name {
  font-weight: 700;
  text-align: left;
  font-size: 1.6rem;
  margin-bottom: 16px;
}
.plan__price {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 700;
}
.price__symbol {
  margin-left: 4px;
}
.price__value {
  font-size: 2rem;
  line-height: 1;
}
.price__value--noprice {
  font-size: 1.2rem;
  line-height: 2rem;
}
.features__item {
  position: relative;
  padding-left: 32px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  & > .checkmark {
    display: block;
    position: absolute;
    left: 0;
    top: 5px;
  }
  & > .checkcross {
    display: block;
    position: absolute;
    left: 0;
    top: 5px;
  }
}

@media screen and (min-width: 768px) {
  .plan__name {
    text-align: center;
  }
  .plan__price {
    justify-content: center;
  }
}
</style>