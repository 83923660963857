<template>
  <div id="app">
    <TopBar :currentSection="currentSection" />
    <Hero />
    <div id="product">
    <ProductRow v-for="(section, index) of sections" :key="index" :isTextLeft="section.isTextLeft" :title="section.title" :content="section.content" :imageSrc="section.imageSrc" :imageAlt="section.imageAlt" />
    </div>
    <Pricing />
    <FAQ />
    <Banner />
    <Contact />
    <Footer />
    <Spinner :isLoading="isLoading" :loadedImages="loadedImages" :imagesLength="imagesLength" />
  </div>
</template>

<script>
import TopBar from './components/TopBar.vue';
import Hero from './components/Hero.vue';
import ProductRow from './components/ProductRow.vue';
import Banner from './components/Banner.vue';
import Pricing from './components/Pricing.vue';
import Contact from './components/Contact.vue';
import Footer from './components/Footer.vue';
import Spinner from './components/Spinner.vue';
import FAQ from './components/FAQ.vue';

const scheme = require('./assets/scheme.png');
const backoffice = require('./assets/backoffice.png');
const growth = require('./assets/growth.png');
const uxui = require('./assets/uxui.png');

export default {
  name: 'app',
  components: {
    TopBar,
    Hero,
    ProductRow,
    Banner,
    Pricing,
    Contact,
    Footer,
    Spinner,
    FAQ,
  },
  data() {
    return {
      isLoading: true,
      imagesLength: 0,
      loadedImages: 0,
      currentSection: null,
      sections: [
        {
          isTextLeft: true,
          title: 'Optimisez la gestion de vos de commandes',
          content: 'Shopscan met fin aux commandes par téléphone, fax, boite vocale automatisée ou e-mails libres.<br><br>L\'application digitalise le processus de prise de commandes pour vos clients. Elle vous redistribue ensuite celles-ci <strong>directement dans vos systèmes et selon vos besoins</strong>.',
          imageSrc: scheme,
          imageAlt: 'Schéma avantages Shopscan',
        },
        {
          isTextLeft: false,
          title: 'Une interface simple et efficace',
          content: 'Offrez à vos clients <strong>une expérience qui va droit au but</strong>, facile à appréhender.<br>Une application sans frontières sur <strong>ordinateurs, smartphones et tablettes</strong>.<br><br>Passer commande est simple et rapide. Vos clients peuvent sauvegarder leur commande en cours et la reprendre plus tard ou copier une commande précédente en quelques clics.',
          imageSrc: uxui,
          imageAlt: 'Dessin d\'une femme qui crée un flux d\'utilisation',
        },
        {
          isTextLeft: true,
          title: 'Pour les petites entreprises...',
          content: 'En tant que fournisseur, vous ne possédez pas beaucoup de clients ni d\'infrastructure particulière ? Vous êtes plutôt habitué aux feuilles Excel ?<br><br>Nous vous proposons une solution "clé sur porte".<br>Notre BackOffice: un portail de gestion <strong>sans frais supplémentaire</strong> !<br><br>Gérez vos articles, vos ventes et suivez l\'évolution de votre stock via votre espace dédié.',
          imageSrc: backoffice,
          imageAlt: 'Dessin d\'un homme qui utilise une application',
        },
        {
          isTextLeft: false,
          title: '...Et les plus grandes ',
          content: 'Votre activité compte de nombreux collaborateurs, génère un volume considérable de commandes et vous possédez déjà une infrastructure informatique ?<br><br><strong>Nous ne remplacons pas votre solution, nous l\'augmentons !</strong>',
          imageSrc: growth,
          imageAlt: 'Dessin d\'un homme en costume se tenant à coté d\'un écran présentant des statistiques',
        },
      ],
    };
  },
  methods: {
    waitForImages() {
      const images = document.querySelectorAll('img');
      this.imagesLength = images.length;
      images.forEach((image) => {
        const interval = setInterval(() => {
          if (image.complete) {
            this.loadedImages += 1;
            clearInterval(interval);
          }
          this.isLoading = !(this.loadedImages === images.length);
        }, 500);
      });
    },
  },
  created() {
    const { replaceState } = history;
    history.replaceState = (...args) => {
      replaceState.apply(history, args);
      // eslint-disable-next-line prefer-destructuring
      this.currentSection = args[2];
    };
    window.addEventListener('scroll', () => {
      const offsets = [0, document.querySelector('#product').offsetTop, document.querySelector('#pricing').offsetTop, document.querySelector('#faq').offsetTop, document.querySelector('#contact').offsetTop];
      let hash = null;
      let match = null;
      offsets.forEach((offset, index) => {
        if (window.scrollY >= offset) {
          match = index;
        }
      });
      switch (match) {
        case 0:
          hash = '#landing';
          break;
        case 1:
          hash = '#product';
          break;
        case 2:
          hash = '#pricing';
          break;
        case 3:
          hash = '#faq';
          break;
        case 4:
          hash = '#contact';
          break;
        default:
          break;
      }
      if (hash) {
        history.replaceState(null, null, hash);
      }
    });
  },
  mounted() {
    this.waitForImages();
  },
};
</script>

<style lang="scss">
// html {
//   scroll-behavior: smooth;
// }
.section {
  background-color: #f5f5f5;
  padding-top: 120px;
  padding-bottom: 120px;
}
.title {
  line-height: 1.5;
}
// @media screen and (min-width: 1024px) {
//   .section {
//     padding-top: 240px;
//     padding-bottom: 240px;
//   }
// }
</style>