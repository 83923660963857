<template>
  <div id="faq" class="section has-background-white">
    <div class="container">
       <div class="columns">
        <div class="column has-text-centered">
          <h1 class="title">Questions fréquentes</h1>
        </div>
       </div>
      <div class="columns">
        <div class="column">
          <ul class="questions">
            <Question v-for="(question, index) of questions" :key="index" :label="question.label" :answer="question.answer" />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Question from './Question.vue';

export default {
  name: 'FAQ',
  components: {
    Question,
  },
  data() {
    return {
      questions: [
        {
          label: 'Comment et à quels systèmes informatiques pouvez-vous vous connecter&nbsp;?',
          answer: 'Si vous possédez un système informatique existant, nous évaluerons ensemble le meilleur moyen d\'y connecter Shopscan.<br><br>Nos développeurs possèdent de nombreuses compétences avec la plupart des solutions actuelles (Azure, SalesForce, SAP, Dynamics, DirectInvoice, Snappyto, ...).',
        },
        {
          label: 'Qu\'est-ce qu\'une transaction&nbsp;?',
          answer: 'Une commande envoyée par un utilisateur<br><br>Un document téléchargé par un utilisateur',
        },
        {
          label: 'Puis-je vendre mes produits à tout le monde via Shopscan&nbsp;?',
          answer: 'Oui !<br><br>Les flux de ventes sont adaptés aux besoins et attentes des particuliers et des professionnels.<br><br>Les professionnels doivent régulièrement commander les mêmes articles chez leurs fournisseurs. Le mode qui leur est adapté permet de commander vos articles plus rapidement que nos concurrents et met en avant des fonctionnalités de copie rapide et de sauvegarde de commandes.<br><br>Les particuliers profitent d\'une expérience plus proche d\'un site eCommerce classique, tout en profitant aussi d\'un flux de commandes plus rapides.<br><br>Nous gérons les paiements directs, différés et les clients qui possèdent une ligne de crédit.',
        },
        {
          label: 'Comment sont gérés les paiements sur la plateforme&nbsp;?',
          answer: 'La gestion des paiements est flexible selon vos besoins.<br><br>Le module de paiement n\'est pas obligatoire si vos clients paient uniquement via facture.<br><br>Pour les autres, nous proposons 2 flux de paiement:<br>- <strong>Direct</strong>, le client paie directement via une interface sécurisée<br>- <strong>Indirect</strong>, le client enregistre ses informations de paiement via une interface sécurisée et est prélevé une fois la commande traitée chez vous',
        },
        {
          label: 'Les prix de mes articles peuvent varier en fonction de certains critères, prenez-vous cela en compte&nbsp;?',
          answer: 'Oui !<br><br>Nous gérons les prix de vos articles si ceux-ci viennent à varier quelqu\'en soit les raisons:<br>- En fonction du client<br>- En fonction de la provenance<br>- En fonction du jour<br>- ...',
        },
        {
          label: 'Je possède plusieurs catalogues d\'articles ainsi que plusieurs catégories, comment cela est-il géré&nbsp;?',
          answer: 'Si vous possédez plusieurs catalogues, catégories et/ou sous-catégories, nous traitons ceux et celles-ci comme filtres dans la liste d\'articles<br><br>Nous gérons aussi les catalogues par client ainsi que leurs articles favoris.',
        },
        {
          label: 'Qu\'entendez-vous par "un design à vos couleurs"&nbsp;?',
          answer: 'Nous déterminons une charte graphique pour votre environnement Shopscan basée sur celle de votre marque.<br>Cela signifie que votre logo et vos images sont ajoutés.<br><br>Vos clients sont donc, jusqu\'à l\'icône de l\'application, dans votre univers graphique.',
        },
        {
          label: 'Je suis déjà client Shopscan et mon activité évolue, comment changer de formule&nbsp;?',
          answer: 'Tout d\'abord, félicitations !<br><br>Nous entretenons des liens serrés avec nos clients.<br>Afin d\'adapter votre forfait, il vous suffit de nous contacter.',
        },
      ],
    };
  },
};
</script>