<template>
  <section class="section">
    <div class="container">
      <div class="columns is-vcentered">
          <template v-if="isTextLeft">
            <div class="column text">
              <h1 class="title mb-6">{{title}}</h1>
              <p v-html="content"></p>
            </div>
            <div class="column">
              <img class="right" :src="imageSrc" :alt="imageAlt" />
            </div>
          </template>
          <template v-else>
            <div class="column">
              <img class="left" :src="imageSrc" :alt="imageAlt" />
            </div>
            <div class="column text">
              <h1 class="title mb-6">{{title}}</h1>
              <p v-html="content"></p>
            </div>
          </template>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ProductRow',
  props: ['isTextLeft', 'title', 'content', 'imageSrc', 'imageAlt'],
};
</script>

<style lang="scss" scoped>
img {
  display: block;
  width: 700px;
}
.left {
  margin-right: auto;
}
.right {
  margin-left: auto;
}
.text {
  max-width: 540px;
}
</style>