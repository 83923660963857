<template>
  <section id="contact" class="section">
    <div class="container">
      <div class="columns is-vcentered">
        <div class="column text">
          <h1 class="title mb-6">Vous désirez une démo de Shopscan ou simplement poser une question&nbsp;?</h1>
          <p>Notre équipe à taille humaine, s'occupe de votre demande avec plaisir!<br>Il vous suffit de remplir le formulaire et nous vous répondrons dans les plus brefs délais.&nbsp;!<br><br>
            <span class="has-text-grey is-size-7">Tous les champs sont obligatoires.</span>
          </p>
        </div>
        <div class="column form">
          <form @submit.prevent="validateForm($event)" novalidate>
            <div class="field">
              <label for="name" class="label">Votre nom</label>
              <div class="control">
                <input id="name" class="input" :class="{ 'is-danger': !isFormValid && !emailCommand.name }" type="text" autocomplete="name" v-model="emailCommand.name" required>
              </div>
              <p v-if="!isFormValid && !emailCommand.name" class="help is-danger">Votre nom est requis</p>
            </div>
            <div class="field">
              <label for="email" class="label">Votre e-mail</label>
              <div class="control">
                <input id="email" maxlength="254" class="input" :class="{ 'is-danger': !isFormValid && !emailCommand.email }" type="email" autocomplete="email" v-model="emailCommand.email" required>
              </div>
              <p v-if="!isFormValid && !emailCommand.email" class="help is-danger">Votre e-mail est requis</p>
            </div>
            <div class="field">
              <label for="phone" class="label">Votre numéro de téléphone</label>
              <div class="control">
                <input id="phone" class="input" :class="{ 'is-danger': !isFormValid && !emailCommand.phone }" type="tel" autocomplete="tel" v-model="emailCommand.phone" required>
              </div>
              <p v-if="!isFormValid && !emailCommand.phone" class="help is-danger">Votre numéro de téléphone est requis</p>
            </div>
            <div class="field">
              <label for="infra" class="label">Votre infrastructure actuelle</label>
              <div class="control">
                <div class="select" :class="{ 'is-danger': !isFormValid && !emailCommand.infra }">
                  <select id="infra" v-model="emailCommand.infra" required>
                    <option value="CRM - ERP">CRM - ERP</option>
                    <option value="Classeur Excel">Classeur Excel</option>
                    <option value="Rien">Rien</option>
                    <option value="Autre">Autre (décrire dans le champs "Votre message")</option>
                  </select>
                </div>
                <p v-if="!isFormValid && !emailCommand.infra" class="help is-danger">Un choix est requis</p>
              </div>
            </div>
            <div class="field">
              <label for="message" class="label">Votre message</label>
              <div class="control">
                <textarea id="message" class="textarea has-fixed-size" :class="{ 'is-danger': !isFormValid && !emailCommand.message }" rows="8" v-model="emailCommand.message" required></textarea>
              </div>
              <p v-if="!isFormValid && !emailCommand.message" class="help is-danger">Un message est requis</p>
            </div>
            <button type="submit" class="button is-gradient" :class="{ 'is-loading': isProcessing }" :disabled="isProcessing">Envoyer</button>
            <div class="notif-container" v-if="isSent || isError">
              <div v-if="isSent" class="notification is-success">
                Message envoyé avec succès!
              </div>
              <div v-if="isError" class="notification is-danger">
                Erreur lors de l'envoi du message.
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Contact',
  data() {
    return {
      isProcessing: false,
      isFormValid: true,
      isSent: false,
      isError: false,
      emailCommand: {
        name: null,
        email: null,
        infra: null,
        message: null,
        phone: null,
        captcha: null,
      },
    };
  },
  methods: {
    validateForm(e) {
      this.isProcessing = true;
      this.isFormValid = e.target.checkValidity();
      Object.keys(this.emailCommand).forEach((key) => {
        if (key !== 'captcha') {
          if (!this.emailCommand[key]) {
            this.isProcessing = false;
          }
        }
      });
      if (this.isProcessing) {
        this.sendEmail();
        this.isFormValid = true;
      }
    },
    sendEmail() {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6Le-uMQUAAAAAKI-MILJfyX158jLpCblx45_W35P', { action: 'submit' })
          .then(async (token) => {
            this.emailCommand.captcha = token;
            try {
              const response = await fetch(`${process.env.VUE_APP_BACKEND}/emails`, {
                method: 'POST',
                body: JSON.stringify(this.emailCommand),
                headers: {
                  'Content-Type': 'application/json',
                },
              });
              if (response.ok) {
                this.resolveAndReset();
              } else {
                this.reject();
              }
            } catch (err) {
              this.reject();
            }
          },
          () => {
            this.reject();
          });
      });
      return false;
    },
    resolveAndReset() {
      this.emailCommand.name = null;
      this.emailCommand.email = null;
      this.emailCommand.infra = null;
      this.emailCommand.message = null;
      this.emailCommand.phone = null;
      this.emailCommand.captcha = null;
      this.isProcessing = false;
      this.isSent = true;
    },
    reject() {
      this.isProcessing = false;
      this.isError = true;
    },
  },
  watch: {
    isSent(val) {
      if (val) {
        setTimeout(() => this.isSent = false, 2000);
      }
    },
    isError(val) {
      if (val) {
        setTimeout(() => this.isError = false, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  max-width: 540px;
  margin-right: auto;
}
.form {
  max-width: 700px;
  margin-left: auto;
}
select, .select, button {
  width: 100%;
}
.notif-container {
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  text-align: center;
}
.notification {
  border-radius: 0;
}
</style>