<template>
  <section class="section is-relative">
    <div class="container">
      <div class="columns">
        <div class="column has-text-centered">
          <span class="title has-text-white">Ne perdez plus de temps, d'énergie et de resources<br>Passez à Shopscan</span>
        </div>
      </div>
      <div class="columns">
        <div class="column has-text-centered">
          <a href="#" class="button is-medium is-gradient mt-6" v-scroll-to="'#contact'">Demander une démo</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Banner',
};
</script>

<style lang="scss" scoped>
@import '@/shared/sass/_variables';

.section {
  padding-top: 64px;
  padding-bottom: 64px;
  background-image: url('../assets/banner.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $primary;
    opacity: .5;
  }
}
@media screen and (min-width: 1024px) {
  .section {
    padding-top: 240px;
    padding-bottom: 240px;
  }
}
</style>