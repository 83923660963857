<template>
  <div v-if="!isHidden" class="spinner has-background-white" :class="{ 'hidden': !isLoading }">
    <div class="svg-container">
      <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 868.2 200" style="enable-background:new 0 0 868.2 200;" xml:space="preserve">
      <g>
        <path d="M1522.2-695.1c-10.1-11.1-16.6-21.2-20.3-30.4c-3.7-8.3-1.8-13.8,5.5-16.6c92.2-31.4,137.4-74.7,137.4-104.2
          c0-34.1-59.9-60.9-59.9-109.7c0-38.7,28.6-73.8,71-97.7c23.1-13.8,51.6-22.1,67.3-22.1c24,0,45.2,9.2,65.5,40.6
          c22.1,35,20.3,46.1,10.1,49.8c-64.5,20.3-133.7,59.9-133.7,92.2c0,27.7,57.2,42.4,57.2,100.5c0,29.5-13.8,59-56.2,89.4
          c-20.3,14.8-57.2,31.4-90.4,31.4C1550.8-672.1,1536.1-680.4,1522.2-695.1z"/>
      </g>
      <text transform="matrix(1 0 0 1 958.7583 -858.9512)" style="font-family:'P22PooperBlackPro'; font-size:367.8094px;">s</text>
      <text transform="matrix(1 0 0 1 1239.7583 -858.9512)" style="font-family:'Cortado'; font-size:367.8094px;">s</text>
      <g>
        <g>
          <path fill="#0854C7" d="M83.5,130.2c10.9,11.3,27.8,20.8,49.1,20.8c18.1,0,26.8-8.6,26.8-17.4c0-11.6-13.4-15.5-31.2-19.7
            c-25.2-5.8-57.6-12.7-57.6-47.2c0-25.7,22.2-46.5,58.6-46.5c24.5,0,44.9,7.4,60.2,21.5L171,65.9C158.5,54.3,141.8,49,126.8,49
            c-14.8,0-22.7,6.5-22.7,15.7c0,10.4,13,13.7,30.8,17.8c25.5,5.8,57.9,13.4,57.9,47.7c0,28.2-20.1,49.5-61.8,49.5
            c-29.6,0-50.9-10-65.5-24.5L83.5,130.2z"/>
        </g>
        <rect x="273.1" y="14.7" fill="#0854C7" width="8" height="170.7"/>
        <g>
          <path fill="#1A1A1A" d="M369.3,109.5c4.8,5,12.2,9.1,21.5,9.1c7.9,0,11.8-3.8,11.8-7.6c0-5.1-5.9-6.8-13.7-8.6
            c-11-2.5-25.2-5.6-25.2-20.7c0-11.3,9.7-20.4,25.6-20.4c10.7,0,19.7,3.2,26.4,9.4l-8,10.5c-5.5-5.1-12.8-7.4-19.4-7.4
            c-6.5,0-9.9,2.8-9.9,6.9c0,4.6,5.7,6,13.5,7.8c11.2,2.5,25.3,5.9,25.3,20.9c0,12.4-8.8,21.7-27.1,21.7c-13,0-22.3-4.4-28.7-10.7
            L369.3,109.5z"/>
          <path fill="#1A1A1A" d="M459.7,100.2c0-6.8-3.5-8.9-9.1-8.9c-5,0-8.9,2.8-11.2,5.8V130h-12.9V62.4h12.9v25c3.1-3.8,9.2-7.6,17.2-7.6
            c10.7,0,15.9,5.9,15.9,15.4V130h-12.9V100.2z"/>
          <path fill="#1A1A1A" d="M482.2,105.5c0-13.9,9.7-25.6,25.9-25.6c16.2,0,26,11.8,26,25.6c0,14-9.7,25.8-26,25.8
            C492,131.2,482.2,119.4,482.2,105.5z M520.6,105.5c0-7.6-4.6-14.2-12.6-14.2c-8,0-12.5,6.6-12.5,14.2c0,7.7,4.5,14.3,12.5,14.3
            C516.1,119.8,520.6,113.2,520.6,105.5z"/>
          <path fill="#1A1A1A" d="M543.6,148.6V81h12.9v6.2c3.8-4.8,9.1-7.4,15.1-7.4c12.6,0,21.7,9.3,21.7,25.6c0,16.3-9.1,25.8-21.7,25.8
            c-5.8,0-11.2-2.4-15.1-7.5v24.9H543.6z M567.5,91.3c-4.2,0-8.9,2.4-11.1,5.6v17.3c2.1,3,6.9,5.6,11.1,5.6
            c7.4,0,12.4-5.8,12.4-14.3C579.9,97,574.9,91.3,567.5,91.3z"/>
          <path fill="#1A1A1A" d="M604.2,114.6c3.5,3.3,10.8,6.7,16.9,6.7c5.6,0,8.2-1.9,8.2-5c0-3.4-4.3-4.7-9.8-5.8
            c-8.4-1.6-19.3-3.6-19.3-15.3c0-8.2,7.2-15.4,20.1-15.4c8.3,0,14.9,2.8,19.7,6.7l-5.1,8.8c-2.9-3.1-8.5-5.7-14.5-5.7
            c-4.7,0-7.7,1.7-7.7,4.6c0,3,3.9,4.2,9.3,5.3c8.4,1.6,19.7,3.9,19.7,16c0,9-7.6,15.7-21.2,15.7c-8.5,0-16.7-2.8-21.9-7.6
            L604.2,114.6z"/>
          <path fill="#1A1A1A" d="M674,79.8c10,0,16.1,4.4,19.4,8.9l-8.4,7.8c-2.3-3.3-5.9-5.3-10.3-5.3c-7.8,0-13.3,5.7-13.3,14.2
            c0,8.5,5.5,14.3,13.3,14.3c4.5,0,8-2,10.3-5.3l8.4,7.8c-3.2,4.6-9.3,8.9-19.4,8.9c-15.1,0-26-10.6-26-25.8
            C648.1,90.5,658.9,79.8,674,79.8z"/>
          <path fill="#1A1A1A" d="M731.2,124.9c-3.3,4-9.1,6.3-15.5,6.3c-7.8,0-17-5.3-17-16.2c0-11.5,9.2-15.6,17-15.6c6.5,0,12.3,2,15.5,6
            v-6.8c0-5-4.3-8.2-10.7-8.2c-5.3,0-10.1,1.9-14.3,5.8l-4.9-8.6c6-5.3,13.7-7.7,21.4-7.7c11.2,0,21.4,4.5,21.4,18.6V130h-12.9
            V124.9z M731.2,112.1c-2.1-2.8-6.2-4.3-10.3-4.3c-5.1,0-9.2,2.7-9.2,7.4c0,4.6,4.2,7.2,9.2,7.2c4.2,0,8.2-1.4,10.3-4.3V112.1z"/>
          <path fill="#1A1A1A" d="M789.9,100.4c0-6.8-3.5-9.1-9-9.1c-5.1,0-9,2.8-11.2,5.8V130h-12.9V81h12.9v6.4c3.1-3.8,9.1-7.6,17.1-7.6
            c10.7,0,15.9,6.1,15.9,15.6V130h-12.9V100.4z"/>
        </g>
      </g>
      <text transform="matrix(1 0 0 1 -341.8667 -697.2344)" fill="#0854C7" style="font-family:'ProximaNova-Bold'; font-size:231.4455px;">S</text>
      <rect x="-130.9" y="-853.3" fill="#0854C7" width="8" height="170.7"/>
      <text transform="matrix(1 0 0 1 -48.648 -733.4648)" fill="#1A1A1A" style="font-family:'ProximaNova-Bold'; font-size:101.3833px;">Shopscan</text>
      </svg>
    </div>
    <span class="loader mt-4"></span>
    <p class="has-text-grey mt-2">{{loadingState}}%</p>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: ['isLoading', 'loadedImages', 'imagesLength'],
  data() {
    return {
      isHidden: false,
    };
  },
  computed: {
    loadingState() {
      const state = (this.loadedImages / this.imagesLength) * 100;
      return state.toFixed(0);
    },
  },
  watch: {
    isLoading(newVal) {
      if (!newVal) {
        setTimeout(() => this.isHidden = true, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity .5s ease;
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.svg-container {
  width: 240px;
  height: 55px;
  overflow: hidden;
}
</style>