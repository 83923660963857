import Vue from 'vue';
// import { ParallaxContainer, ParallaxElement } from 'vue-mouse-parallax';
import VueScrollTo from 'vue-scrollto';
import './shared/sass/theme.scss';
import App from './App.vue';

// Vue.component('parallax-container', ParallaxContainer);
// Vue.component('parallax-element', ParallaxElement);
Vue.use(VueScrollTo);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app');
