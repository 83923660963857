<template>
  <footer class="section has-background-white py-mobile-6 py-desktop-12">
    <div class="container">
      <div class="columns is-variable is-8-desktop">
        <div class="column mb-mobile-6">
          <p class="has-text-primary has-text-weight-bold mb-4">Shopscan c'est quoi&nbsp;?</p>
          <p>Shopscan est une application de gestion de commandes récurrentes pour les fournisseurs et leurs clients.</p>
        </div>
        <div class="column mb-mobile-6">
          <p class="has-text-primary has-text-weight-bold mb-4">Shopscan c'est pour qui&nbsp;?</p>
          <p>L'application est destinée à tout grossiste qui souhaite digitaliser ou améliorer la gestion de ses commandes</p>
        </div>
        <div class="column">
          <p class="has-text-primary has-text-weight-bold mb-4">Informations de contact</p>
          <p>Shopscan - <a href="https://www.corellia.be" title="Shopscan" target="_blank">www.shopscan.be</a>
            <br>
            <span v-html="address"></span>
            <br><br>
            <a href="mailto:philippe@itiko.be" title="Contact commercial">hello@shopscan.be</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    address() {
      return location.host.includes('.fr') ? '1,&nbsp;Rue de l\'innovation<br>59260&nbsp;Lille&nbsp;-&nbsp;France' : 'Avenue des bassins,&nbsp;64<br>7000&nbsp;Mons&nbsp;-&nbsp;Belgique';
    },
  },
};
</script>

<style lang="scss" scoped>

</style>