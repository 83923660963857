<template>
  <nav
    class="navbar is-fixed-top"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a
        class="navbar-item"
        href="#"
        v-scroll-to="'#landing'"
        aria-label="Retourner au début de la page"
      >
        <img src="@/assets/logo.svg" alt="Logo Shopscan" />
      </a>

      <a
        role="button"
        @click="toggleMenu()"
        class="navbar-burger burger"
        :class="showMenu"
        aria-label="menu"
        aria-expanded="false"
        data-target="navBar"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navBar" class="navbar-menu" :class="showMenu">
      <div class="navbar-end">
        <a
          href="#"
          v-scroll-to="'#landing'"
          class="navbar-item has-text-primary"
          :class="{
            'is-active': !currentSection || currentSection === '#landing',
          }"
          @click="toggleMenu()"
        >
          Accueil
        </a>
        <a
          href="#product"
          v-scroll-to="'#product'"
          class="navbar-item has-text-primary"
          :class="{ 'is-active': currentSection === '#product' }"
          @click="toggleMenu()"
        >
          Produit
        </a>

        <a
          href="#pricing"
          v-scroll-to="'#pricing'"
          class="navbar-item has-text-primary"
          :class="{ 'is-active': currentSection === '#pricing' }"
          @click="toggleMenu()"
        >
          Tarifs
        </a>
        <a
          href="#faq"
          v-scroll-to="'#faq'"
          class="navbar-item has-text-primary"
          :class="{ 'is-active': currentSection === '#faq' }"
          @click="toggleMenu()"
        >
          FAQ
        </a>
        <a
          href="#contact"
          v-scroll-to="'#contact'"
          class="navbar-item has-text-primary"
          :class="{ 'is-active': currentSection === '#contact' }"
          @click="toggleMenu()"
        >
          Contact
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'TopBar',
  props: ['currentSection'],
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isActive = !this.isActive;
    },
  },
  computed: {
    showMenu() {
      return { 'is-active': this.isActive };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/shared/sass/_variables";

.navbar {
  border-bottom: 1px solid #ededf4;
}

.navbar-menu .navbar-item {
  text-transform: uppercase;
  padding: 2rem;
  position: relative;
  &:hover,
  &.is-active {
    font-weight: 700;
  }
}

.navbar-item img {
  height: 100%;
  min-width: 120px;
}

@media screen and (min-width: 1024px) {
  .navbar {
    height: 72px;
    &.scrolled {
      height: 56px;
    }
  }
  .navbar-item img {
    height: 40px;
    max-height: none;
  }
  .navbar-menu .navbar-item {
    padding: 0.5rem 2rem;
    &:hover,
    &.is-active {
      font-weight: 700;
    }
    &:after {
      content: "";
      display: none;
      position: absolute;
      bottom: 0;
      height: 4px;
      background-color: $orange;
      transition: all 0.2s ease;
    }
    &.is-active:after {
      display: block;
      left: 2rem;
      width: calc(100% - 4rem);
    }
    &:hover:after {
      display: block;
      left: 0;
      width: 100%;
    }
  }
}
</style>
