<template>
  <li class="questions__question-item">
    <div class="question-item__label" :class="{ 'question-item__label--active': expanded }" @click="toggle()">
      <span v-html="label"></span>
      <span class="label__arrow">&#x276f;</span>
    </div>
    <div class="question-item__answer" :class="{ 'question-item__answer--active': expanded }" v-html="answer">
    </div>
  </li>
</template>

<script>
export default {
  name: 'Question',
  props: {
    label: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/sass/_variables';

.questions__question-item {
  transition: all .3s ease;
  overflow: hidden;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
.question-item__label {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  transition: inherit;
  border: 1px solid #eeeeee;
  border-left: 4px solid #999999;
  border-radius: 4px;
  font-weight: 700;
  font-size: 1.2rem;
  padding: .5rem 1rem;
  & > .label__arrow {
    text-align: center;
    margin-left: 16px;
    transform: rotate(90deg);
    transition: all .35s;
  }
  &:hover, &.question-item__label--active {
    border-left-color: $primary;
  }
  &.question-item__label--active > .label__arrow {
    transform: rotate(-90deg);
  }
}
.question-item__answer {
  transition: inherit;
  border: 1px solid #eeeeee;
  background-color: #fafafa;
  padding: 1rem;
  line-height: 1.5;
  height: 0;
  padding: 0 1rem;
  will-change: height, padding;
}
.question-item__answer--active {
  height: auto;
  padding: 1rem;
}
</style>