<template>
  <parallax-container tag="header" class="hero" id="landing">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column">
            <h1 class="title is-spaced">
              Digitalisez les flux de<br> communication avec vos clients
            </h1>
            <h2 class="subtitle">
              Shopscan est votre assistant commercial et administratif <br>
              qui gère les relations quotidiennes avec vos clients.
              <br><br>
              Automatisation - Réduction des coûts - Fidélisation
            </h2>
            <a href="#" class="button is-medium is-gradient mt-6" v-scroll-to="'#contact'">Demander une démo</a>
          </div>
          <div class="column is-relative">
              <parallax-element class="blob" :parallaxStrength="50" type="rotation" tag="div">
              </parallax-element>
            <img :src="screenshot" alt="Capture d'écran Shopscan" class="screenshot" />
          </div>
        </div>
      </div>
    </div>
    <div class="hero-foot">
      <span class="mouse"></span>
    </div>
  </parallax-container>
</template>

<script>
const screenshot = require('../assets/shopscan-screens.png');

export default {
  name: 'Hero',
  data() {
    return {
      screenshot,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/sass/_variables';

.hero {
  min-height: 100vh;
  padding-top: 52px;
}
.hero-body {
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  font-weight: 700;
  color: $primary;
}

.blob {
  position: absolute;
  z-index: -1;
  width: 80vw;
  height: 80vw;
  background-image: $primary-gradient;
  border-radius: 20%;
  top: 68px;
  left: 0;
  transform: rotate(12deg);
}

.screenshot {
  margin-top: 48px;
}

.mouse {
  display: none;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
        opacity: 0
    }

    50% {
        transform: translateY(0);
        opacity: 1
    }

    100% {
        transform: translateY(10px) scale(.5);
        opacity: 0
    }
}

@media screen and (min-width: 1024px) {
  .hero {
    padding-top: 0;
  }

  .hero-body {
    padding-top: 240px;
    padding-bottom: 240px;
    overflow: hidden;
  }
  .title {
    font-size: 2.25rem;
  }
  .blob {
    position: absolute;
    z-index: -1;
    width: 120vh;
    height: 120vh;
    background-image: $primary-gradient;
    border-radius: 20%;
    top: -480px;
    left: 12px;
    transform: rotate(12deg);
  }

  .screenshot {
    margin-top: 0;
  }

  .mouse {
    display: block;
    position: absolute;
    width: 24px;
    height: 36px;
    border: 1px solid $primary;
    border-radius: 20px;
    left: calc(50% - 12px);
    bottom: 40px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 4px;
      height: 4px;
      background-color: $primary;
      border-radius: 4px;
      top: 10px;
      left: calc(50% - 2px);
      -webkit-animation: scroll 2s ease infinite;
      animation: scroll 2s ease infinite;
    }
  }
}
</style>
